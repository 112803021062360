import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  errorImg: {
    width: "100%",
    height: 300,
  },
  errorButton: {
    marginTop: theme.spacing(3),
  },
}));

export { useStyles };
