// Enum of action types
export enum ActionTypes {
  UPDATE_ROW = "UPDATE_ROW",
  ADD_ITEM = "ADD_ITEM",
  UPDATE_REVIEW = "UPDATE_REVIEW",
  UPDATE_DATA = "UPDATE_DATA",
  INITIALIZE = "INITIALIZE",
  CLEAR_REVIEW = "CLEAR_REVIEW",
}

// Action Interfaces
interface IUpdateRowAction {
  type: ActionTypes.UPDATE_ROW;
  payload: any;
}

interface IAddItemAction {
  type: ActionTypes.ADD_ITEM;
  payload: any;
}

interface IUpdateReviewAction {
  type: ActionTypes.UPDATE_REVIEW;
  payload: any;
}

interface IClearReviewAction {
  type: ActionTypes.CLEAR_REVIEW;
  payload: any;
}

interface IUpdateDataAction {
  type: ActionTypes.UPDATE_DATA;
  payload: any;
}

interface IInitializeAction {
  type: ActionTypes.INITIALIZE;
  payload: any;
}

// Dispatch trigger functions
export const triggerUpdateRow = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_ROW,
    payload,
  };
};

export const triggerAddItem = (payload: any) => {
  return {
    type: ActionTypes.ADD_ITEM,
    payload,
  };
};

export const triggerUpdateReview = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_REVIEW,
    payload,
  };
};

export const triggerUpdateData = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_DATA,
    payload,
  };
};

export const triggerClearReview = () => {
  return {
    type: ActionTypes.CLEAR_REVIEW,
  };
};

export const triggerInitialize = () => {
  return {
    type: ActionTypes.INITIALIZE,
  };
};

// Alert action Types used in reducer
export type AutomatchActionTypes =
  | IClearReviewAction
  | IUpdateRowAction
  | IAddItemAction
  | IUpdateReviewAction
  | IUpdateDataAction
  | IInitializeAction;
