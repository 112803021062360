export const defaultValues = {
  productDashboard: {
    order: {
      field: "name",
      sort: "asc",
    },
    columns: [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        width: 320,
      },
      {
        field: "profit",
        headerName: "Profit",
        hide: false,
        width: 120,
      },
      {
        field: "minPrice",
        headerName: "Min Price",
        hide: false,
        width: 120,
      },
      {
        field: "ourPrice",
        headerName: "Our Price",
        hide: false,
        width: 120,
      },
      {
        field: "ourPosition",
        headerName: "Position",
        hide: false,
        width: 120,
      },
      {
        field: "srp",
        headerName: "SRP",
        hide: false,
        width: 120,
      },
      {
        field: "categories",
        headerName: "Categories",
        hide: false,
        width: 240,
      },
      {
        field: "competitorsNum",
        headerName: "Competitors",
        hide: false,
        width: 120,
      },
      {
        field: "updated",
        headerName: "Updated",
        hide: false,
        width: 120,
      },
      {
        field: "isEnabled",
        headerName: "Status",
        hide: false,
        width: 120,
      },
    ],
    rowsPerPage: 10,
  },
  competitorDashboard: {
    order: {
      field: "name",
      sort: "asc",
    },
    columns: [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        width: 320,
      },
      {
        field: "stores",
        headerName: "Linked Stores",
        hide: false,
        width: 240,
      },
      {
        field: "productsNum",
        headerName: "Products",
        hide: false,
        width: 120,
      },
      {
        field: "avgPosition",
        headerName: "Avg Position",
        hide: false,
        width: 120,
      },
      {
        field: "categories",
        headerName: "Total Categories",
        hide: false,
        width: 120,
      },
      {
        field: "avgPrice",
        headerName: "Avg Price",
        hide: false,
        width: 120,
      },
      {
        field: "storeLink",
        headerName: "Store Link",
        hide: false,
        width: 120,
      },
      {
        field: "skroutzLink",
        headerName: "Skroutz Link",
        hide: false,
        width: 120,
      },
      {
        field: "isIncluded",
        headerName: "Status",
        hide: false,
        width: 120,
      },
    ],
    rowsPerPage: 10,
  },
  categoryDashboard: {
    order: {
      field: "name",
      sort: "asc",
    },
    columns: [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        width: 320,
      },
      {
        field: "productsNum",
        headerName: "Products",
        hide: false,
        width: 120,
      },
      {
        field: "competitorsNum",
        headerName: "Total Competitors",
        hide: false,
        width: 120,
      },
      {
        field: "commonCat",
        headerName: "Common Categories",
        hide: false,
        width: 120,
      },
      {
        field: "favorite",
        headerName: "Status",
        hide: false,
        width: 120,
      },
    ],
    rowsPerPage: 10,
  },
  categoryProductsDashboard: {
    order: {
      field: "name",
      sort: "asc",
    },
    columns: [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        width: 320,
      },
      {
        field: "price",
        headerName: "Price",
        hide: false,
        width: 120,
      },
      {
        field: "position",
        headerName: "Position",
        hide: false,
        width: 120,
      },
      {
        field: "competitorsNum",
        headerName: "Competitors",
        hide: false,
        width: 120,
      },
      {
        field: "categoriesNum",
        headerName: "Categories",
        hide: false,
        width: 120,
      },
      {
        field: "categories",
        headerName: "Common Categories",
        hide: false,
        width: 240,
      },
    ],
    rowsPerPage: 10,
  },
  categoryCompetitorsDashboard: {
    order: {
      field: "name",
      sort: "asc",
    },
    columns: [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        width: 320,
      },
      {
        field: "avgPrice",
        headerName: "Average Price",
        hide: false,
        width: 120,
      },
      {
        field: "avgPosition",
        headerName: "Average Position",
        hide: false,
        width: 120,
      },
      {
        field: "productsNum",
        headerName: "Products",
        hide: false,
        width: 120,
      },
      {
        field: "categories",
        headerName: "Common Categories",
        hide: false,
        width: 240,
      },
    ],
    rowsPerPage: 10,
  },
  productPage: {
    graph: {
      dateRange: "this_week",
      graphCompetitors: 5,
    },
    grid: {
      dateRange: "this_week",
      rowsPerPage: 10,
    },
  },
};
