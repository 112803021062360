// Enum of action types
export enum ActionTypes {
  CHANGE_ACTIVE_MENU = "CHANGE_ACTIVE_MENU",
}

// Action Interfaces
interface IChangeActiveMenu {
  type: ActionTypes.CHANGE_ACTIVE_MENU;
  payload: number;
}

export const triggerChangeActiveMenu = (menuItemIndex: number) => {
  return {
    type: ActionTypes.CHANGE_ACTIVE_MENU,
    payload: menuItemIndex,
  };
};

export type LayoutActionTypes = IChangeActiveMenu;
