import {
  Configuration,
  ConfigurationType,
  IDashboard,
  IProductPage,
} from "../entities/configurations";
import { API } from "../helpers/api";
import { cookieStore } from "../helpers/storage";

// TODO change any
export const getConfigurations = async (
  username: string,
): Promise<Configuration> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.get(`/configurations/${username}`);
  return response;
};

// TODO create a data type for post and delete
export const setConfigurations = async (
  username: string,
  data: any,
): Promise<any> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.post(
    `/configurations/?username=${username}`,
    data,
  );
  return response;
};

export const updateConfigurationForPage = async (
  username: string,
  configurationName: string,
  data: any,
): Promise<any> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.post(
    `/configurations/${configurationName}/?username=${username}`,
    data,
  );
  return response;
};

// Not used for the time being
export const deleteConfigurations = async (
  username: string,
  configurationName: string,
): Promise<any> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.delete(
    `/configurations/?username=${username}&conf_name=${configurationName}`,
  );
  return response;
};

export const getConfigurationForPage = async (
  username: string,
  configuration_name: ConfigurationType,
): Promise<IDashboard | IProductPage> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.get(
    `/configurations/${username}/${configuration_name}`,
  );
  return response;
};

export const reorderDashboard = async (
  username: string,
  configuration_name: ConfigurationType,
  fixOrder: number,
  data: any,
): Promise<any> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.put(
    `/configurations/reorder/${configuration_name}?username=${username}&fix_order=${fixOrder}`,
    data,
  );
  return response;
};

export const resizeDashboard = async (
  username: string,
  configuration_name: ConfigurationType,
  data: any,
): Promise<any> => {
  const api = new API({
    headers: {
      Authorization: cookieStore.get("token")
        ? `Bearer ${cookieStore.get("token")}`
        : "",
    },
  });

  const response = await api.put(
    `/configurations/resize/${configuration_name}?username=${username}`,
    data,
  );
  return response;
};
