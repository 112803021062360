export enum ActionTypes {
  CATEGORY = "RETRIEVE_CATEGORY_NAME",
}

interface ICategoryName {
  type: ActionTypes.CATEGORY;
  payload: string;
}

export const retrieveCategoryName = (name: string) => {
  return {
    type: ActionTypes.CATEGORY,
    payload: name,
  };
};

export type CategoryActionTypes = ICategoryName;
