import React from "react";

import { Button } from "../../../components/ui-kit/button";
import { Grid } from "../../../components/ui-kit/grid";
import { Typography } from "../../../components/ui-kit/typography";
import { Error } from "../../../helpers/imageImport";
import { useTriggerErrorEmail } from "../../../redux/alert/hooks";
import { useStyles } from "./notfound.styles";

interface NotFoundProps {
  error: any;
  errorInfo: any;
  code: number;
}

const NotFound = ({ error, errorInfo, code }: NotFoundProps) => {
  const classes = useStyles();
  const errorEmailAlert = useTriggerErrorEmail();

  if (code !== 404) {
    errorEmailAlert(
      `URL: ${window.location.href}\n${error}\n${errorInfo.componentStack}`,
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid container direction="row" justifyContent="center">
        <Error className={classes.errorImg} />
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
        >
          <Typography variant="h4">Error {code} - Page not found</Typography>
          <Typography variant="body1">
            The page you requested could not be found
          </Typography>
          <Button
            className={classes.errorButton}
            color="primary"
            href="/"
            variant="contained"
          >
            Back to home
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFound;
