import React from "react";
import { Navigate } from "react-router-dom";

const Competitors = React.lazy(
  () => import("./containers/app/CompetitorsDashboard/Competitors"),
);
const CompetitorPage = React.lazy(
  () => import("./containers/app/CompetitorPage/CompetitorPage"),
);
const Home = React.lazy(() => import("./containers/app/Home/Home"));
const Products = React.lazy(
  () => import("./containers/app/ProductsDashboard/Products"),
);
const ProductPage = React.lazy(
  () => import("./containers/app/ProductPage/ProductPage"),
);
const Automatch = React.lazy(
  () => import("./containers/app/Automatch/Automatch"),
);
const AutomatchEdit = React.lazy(
  () => import("./containers/app/AutomatchEdit/Edit"),
);
const Profile = React.lazy(() => import("./containers/app/Profile/Profile"));
const Categories = React.lazy(
  () => import("./containers/app/CategoriesDashboard/CategoriesDashboard"),
);
const Category = React.lazy(
  () => import("./containers/app/CategoryPage/Category"),
);
const Brands = React.lazy(
  () => import("./containers/app/BrandsDashboard/Brands"),
);
const Brand = React.lazy(() => import("./containers/app/BrandPage/Brand"));
const TaskPage = React.lazy(() => import("./containers/app/TaskPage/TaskPage"));
// const Report = React.lazy(() => import("./containers/app/Report/"));
const Tools = React.lazy(() => import("./containers/app/Tools/Tools"));
const Login = React.lazy(() => import("./containers/public/Login/Login"));
const WelcomePage = React.lazy(
  () => import("./containers/public/WelcomePage/Welcome"),
);
// const Configurations = React.lazy(
//   () => import("./containers/app/Configurations/Configurations"),
// );
const NotFound = React.lazy(
  () => import("./containers/public/NotFound/NotFound"),
);
const ErrorPage = React.lazy(
  () => import("./containers/public/ErrorPage/ErrorPage"),
);
const DashBoardLayout = React.lazy(
  () => import("./layouts/DashBoardLayout/DashBoardLayout"),
);
const PublicLayout = React.lazy(
  () => import("./layouts/PublicLayout/PublicLayout"),
);
// path -- the base path of the components
// element -- the wrapepr component use it for layout - auth checking
// children -- route elements, you put the path ex. app/[child.path] and renders the element prop.

const routes = [
  {
    path: "",
    element: <PublicLayout />,
    children: [
      { path: "", element: <Navigate to="/login" /> },
      { path: "/login", element: <Login /> },
      { path: "/welcome", element: <WelcomePage /> },
      {
        path: "/*",
        element: <NotFound code={404} error={null} errorInfo={null} />,
      },
      {
        path: "/404",
        element: <NotFound code={404} error={null} errorInfo={null} />,
      },
      {
        path: "/500",
        element: <ErrorPage code={500} error={null} errorInfo={null} />,
      },
    ],
  },
  {
    path: "/app",
    element: <DashBoardLayout />,
    children: [
      { path: "/app", element: <Home /> },
      { path: "/app/products", element: <Products /> },
      { path: "/app/products/:id/:name", element: <ProductPage /> },
      { path: "/app/competitors", element: <Competitors /> },
      { path: "/app/competitors/:id/:name", element: <CompetitorPage /> },
      { path: "/app/categories", element: <Categories /> },
      { path: "/app/categories/:id/:name", element: <Category /> },
      { path: "/app/brands", element: <Brands /> },
      { path: "/app/brands/:id/:name", element: <Brand /> },
      { path: "/app/tools", element: <Tools /> },
      { path: "/app/tools/automatch/:id", element: <Automatch /> },
      { path: "/app/tools/automatch/:case/:id", element: <AutomatchEdit /> },
      { path: "/app/tools/task/:id", element: <TaskPage /> },
      // { path: "/app/configurations", element: <Configurations /> },
      { path: "/app/profile", element: <Profile /> },
      // { path: "/report", element: <Report /> },
      {
        path: "/app/*",
        element: <NotFound code={404} error={null} errorInfo={null} />,
      },
    ],
  },
];

export default routes;
