/* eslint-disable react/no-unused-state */
import React, { Component, ErrorInfo, ReactNode } from "react";

// import ErrorPage from "../../containers/public/ErrorPage/ErrorPage";
import NotFound from "../../containers/public/NotFound/NotFound";

interface Props {
  children: ReactNode;
}

interface State {
  error: any;
  errorInfo: any;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
    errorInfo: null,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  public render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      return <NotFound code={404} error={error} errorInfo={errorInfo} />;
    }
    // if (error !== null) {
    //   return <ErrorPage code={500} error={error} errorInfo={errorInfo} />;
    // }
    return children;
  }
}

export default ErrorBoundary;
