import React from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box/Box";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { ActionTypes, triggerClose } from "../../redux/alert/actions";

interface IAlertLinearProgress {
  duration: number;
  type: ActionTypes;
}

const getPrimaryLineColor = (type: ActionTypes, theme: Theme) => {
  switch (type) {
    case ActionTypes.ERROR:
      return theme.palette.error.main;
    case ActionTypes.ERROR_EMAIL:
      return theme.palette.error.main;
    case ActionTypes.WARNING:
      return theme.palette.warning.main;
    case ActionTypes.SUCCESS:
      return theme.palette.success.main;
    case ActionTypes.INFO:
      return theme.palette.primary.dark;
    default:
      return theme.palette.lime;
  }
};

const getSecondaryLineColor = (type: ActionTypes, theme: Theme) => {
  switch (type) {
    case ActionTypes.ERROR:
      return theme.palette.error.light;
    case ActionTypes.ERROR_EMAIL:
      return theme.palette.error.light;
    case ActionTypes.WARNING:
      return theme.palette.warning.light;
    case ActionTypes.SUCCESS:
      return theme.palette.success.light;
    case ActionTypes.INFO:
      return theme.palette.primary.light;
    default:
      return theme.palette.primary.light;
  }
};

const AlertLinearProgress = ({ duration, type }: IAlertLinearProgress) => {
  const [progress, setProgress] = React.useState(100);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: getSecondaryLineColor(type, theme),
      "&>*": {
        backgroundColor: getPrimaryLineColor(type, theme),
      },
    },
  }));

  const classes = useStyles();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = (100 / duration) * 100;
        const fixedDiff = parseFloat(diff.toFixed(4));
        if (oldProgress - fixedDiff > 0) return oldProgress - fixedDiff;
        return 0;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (progress === 0) {
        dispatch(triggerClose());
      }
    }, 400);
  }, [progress]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        className={classes.root}
        sx={{ borderRadius: "0 0 16px 16px" }}
        value={progress}
        variant="determinate"
      />
    </Box>
  );
};

export default AlertLinearProgress;
