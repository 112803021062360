import { ActionTypes, CompetitortActionTypes } from "./actions";

interface ICompetitortState {
  competitorName: string;
  type: ActionTypes;
}

const initialState: ICompetitortState = {
  competitorName: "",
  type: ActionTypes.COMPETITORNAME,
};

const triggerCompetitorName = (competitorName: string) => {
  return {
    competitorName,
    type: ActionTypes.COMPETITORNAME,
  };
};
const competitorReducer = (
  state = initialState,
  action: CompetitortActionTypes,
) => {
  switch (action.type) {
    case ActionTypes.COMPETITORNAME:
      return triggerCompetitorName(action.payload);
    default:
      return state;
  }
};

export default competitorReducer;
