/* eslint-disable max-classes-per-file */
import dayjs from "dayjs";

interface IStorage {
  get: (name: string) => string | null;
  set: (name: string, value: string) => void;
  clear: (name: string) => void;
}

class MyLocalStorage implements IStorage {
  get = (name: string) => {
    if (localStorage.getItem(name)) {
      const parts = localStorage.getItem(name)?.split("+");
      const now = new Date();
      if (parts !== undefined && now.getTime() < Number(parts[1])) {
        return parts[0];
      }
    }
    return null;
  };

  set = (name: string, value: string) => {
    const date = dayjs().add(2, "days").add(8, "hours").toDate();
    const item = `${value}+${date.getTime()}`;
    localStorage.setItem(name, item);
  };

  clear = (name: string) => {
    localStorage.setItem(name, "");
  };
}
class CookieStorage implements IStorage {
  get = (name: string) => {
    return this.getCookie(name);
  };

  set = (name: string, value: string) => {
    this.setCookie(name, value);
  };

  clear = (name: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  private setCookie = (
    cookieName: string,
    cookieValue: string,
    exdays = 2.35,
  ) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/;`;
  };

  private getCookie = (cookieName: string) => {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
}
const cookieStore: IStorage = new CookieStorage();
const localStore: IStorage = new MyLocalStorage();

export { cookieStore, localStore };
