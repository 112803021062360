// Enum of action types
export enum ActionTypes {
  LOADINGON = "LOADING_ON",
  LOADINGOFF = "LOADING_OFF",
}

// Action Interfaces
interface ILoadingOnAction {
  type: ActionTypes.LOADINGON;
}

interface ILoadingOffAction {
  type: ActionTypes.LOADINGOFF;
}
// Dispatch trigger functions

export const triggerLoadingOn = () => {
  return {
    type: ActionTypes.LOADINGON,
  };
};

export const triggerLoadingOff = () => {
  return {
    type: ActionTypes.LOADINGOFF,
  };
};

// Alert action Types used in reducer
export type LoadingActionTypes = ILoadingOnAction | ILoadingOffAction;
