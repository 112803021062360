export enum ActionTypes {
  BRAND = "RETRIEVE_BRAND_NAME",
}

interface IBrandName {
  type: ActionTypes.BRAND;
  payload: string;
}

export const retrieveBrandName = (name: string) => {
  return {
    type: ActionTypes.BRAND,
    payload: name,
  };
};

export type BrandActionTypes = IBrandName;
