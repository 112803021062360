export enum ActionTypes {
  PRODUCTNAME = "RETRIEVE_PRODUCT_NAME",
}

interface IProductName {
  type: ActionTypes.PRODUCTNAME;
  payload: string;
}

export const triggerProductName = (name: string) => {
  return {
    type: ActionTypes.PRODUCTNAME,
    payload: name,
  };
};

export type ProductActionTypes = IProductName;
