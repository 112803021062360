import axios from "axios";
import qs from "querystring";

interface IApiConfig {
  baseURL?: string;
  headers?: any;
}

// DO not build an object here and later export it, the reason is that it never gets the proper headers since it inits on app launch.
// So if you do something with a token and its empty to begin with it wont detect the change.
export class API {
  constructor(private config: IApiConfig = {}) {
    this.config.baseURL = process.env.REACT_APP_SERVER_API;
  }

  public async get(url: string): Promise<any> {
    try {
      const response = await axios.get(url, this.config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async post(url: string, data: any = {}): Promise<any> {
    try {
      const response = await axios.post(url, data, this.config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async urlPost(url: string, data: any = {}): Promise<any> {
    try {
      const response = await axios.post(url, qs.stringify(data), this.config);
      return response;
    } catch (error) {
      return error;
    }
  }

  public async patch(url: string, data: any = {}): Promise<any> {
    try {
      const response = await axios.put(url, data, this.config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async put(url: string, data: any = {}): Promise<any> {
    try {
      const response = await axios.put(url, data, this.config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async delete(url: string, data: any = {}): Promise<any> {
    try {
      const response = await axios.delete(url, {
        ...this.config,
        data: { ...data },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
}
