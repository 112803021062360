import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { LicenseInfo } from "@mui/x-data-grid-pro";

import App from "./App";
import { globalStore } from "./redux";

LicenseInfo.setLicenseKey(
  "66a7abf194d2c355cde7924e72a89daeT1JERVI6MzU5NTksRVhQSVJZPTE2NzM3OTEwNDcwMDAsS0VZVkVSU0lPTj0x",
);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={globalStore}>
          <App />
        </Provider>
      </BrowserRouter>
      {/* ***IMPORTANT*** BEFORE PUSHING ON MASTER, COMMENT THE LINE BELOW */}
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
