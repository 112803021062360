import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Typography } from "../ui-kit/typography";

interface MailtoProps {
  email: string;
  subject: string;
  body: string;
  children: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecorationColor: theme.palette.primary.dark,
  },
}));

export const Mailto = ({ email, subject, body, children }: MailtoProps) => {
  const classes = useStyles();
  return (
    <a
      className={classes.link}
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ""
      }&body=${encodeURIComponent(body) || ""}`}
    >
      <Typography
        sx={
          children === "Forgot password?"
            ? { color: (theme) => theme.palette.primary.main }
            : undefined
        }
        variant="body2"
      >
        {children}
      </Typography>
    </a>
  );
};
