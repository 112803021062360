import { ActionTypes, BrandActionTypes } from "./actions";

interface IBrandState {
  brand: string;
  type: ActionTypes;
}

const initialState: IBrandState = {
  brand: "",
  type: ActionTypes.BRAND,
};

const triggerBrandName = (brand: string) => {
  return {
    brand,
    type: ActionTypes.BRAND,
  };
};
const brandReducer = (state = initialState, action: BrandActionTypes) => {
  switch (action.type) {
    case ActionTypes.BRAND:
      return triggerBrandName(action.payload);
    default:
      return state;
  }
};

export default brandReducer;
