export enum ActionTypes {
  COMPETITORNAME = "RETRIEVE_COMPETITOR_NAME",
}

interface ICompetitorName {
  type: ActionTypes.COMPETITORNAME;
  payload: string;
}

export const retrieveCompetitorName = (name: string) => {
  return {
    type: ActionTypes.COMPETITORNAME,
    payload: name,
  };
};

export type CompetitortActionTypes = ICompetitorName;
