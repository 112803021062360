import React, { Suspense, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useRoutes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

import "./App.css";
import AlertComponent from "./components/helper-components/AlertComponent";
import ErrorBoundary from "./components/helper-components/ErrorBoudary";
import { withRouter } from "./components/helper-components/ReactRouterGTM";
import { defaultValues } from "./entities/configDefaults";
import { localStore } from "./helpers/storage";
import routes from "./routes";
import { getConfigurations } from "./services/configurationsApi";
import applicationTheme from "./theme/main/applicationTheme";

function App() {
  const queryClient = useQueryClient();
  const globalTheme = createTheme(
    applicationTheme("purpleTheme", "light", "ltr"),
  );
  const userInfo = localStore.get("username");

  const ReactRouterGTM = withRouter(React.Fragment);

  const { data } = useQuery(
    "configurations",
    () => getConfigurations(userInfo ?? "default"),
    { staleTime: Infinity },
  );

  useEffect(() => {
    if (!data) {
      queryClient.setQueryData("configurations", defaultValues);
    }
  }, [data]);

  const routing = useRoutes(routes);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={globalTheme}>
        <ReactRouterGTM />
        <CssBaseline />
        <Suspense fallback>
          <ErrorBoundary>{routing}</ErrorBoundary>
        </Suspense>
        {/* ALERT COMPONENT IF IN NEED TO TRIGGER AN ALERT JSUT DISPATCH  
        example: triggerError("error test")
      */}
        <AlertComponent />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
