import { localStore } from "../../helpers/storage";
import { IPalette, ThemeMode } from "../../theme/main/palette/IPalette";
// eslint-disable-next-line import/no-cycle
import { ThemeActionTypes, ThemeTypes } from "./actions";

export interface IUiThemeState {
  theme: keyof IPalette;
  type: ThemeMode;
  direction: "rtl" | "ltr";
}

const themeSettings = localStore.get("themeSettings");

const fallbackTheme: IUiThemeState = {
  theme: "purpleTheme",
  type: "light",
  direction: "ltr",
};

const initialState: IUiThemeState = themeSettings
  ? JSON.parse(themeSettings)
  : fallbackTheme;

function updateThemeStorage(themeSettings: IUiThemeState) {
  // saveUiConfiguration(themeSettings, 16);
  localStore.set("themeSettings", JSON.stringify(themeSettings));
  return themeSettings;
}
const themeReducer = (state = initialState, action: ThemeActionTypes) => {
  switch (action.type) {
    case ThemeTypes.GET_THEME:
      return state;
    case ThemeTypes.SET_THEME:
      return updateThemeStorage({ ...state, ...action.payload });
    case ThemeTypes.CHANGE_PALETTE:
      return updateThemeStorage({
        ...state,
        theme: action.payload,
      });
    case ThemeTypes.TOGGLE_THEME: {
      return updateThemeStorage({
        ...state,
        type: (state.type === "light" ? "dark" : "light") as ThemeMode,
      });
    }
    default:
      return state;
  }
};

export default themeReducer;
