import { ActionTypes, ProductActionTypes } from "./actions";

interface IProductState {
  productName: string;
  type: ActionTypes;
}

const initialState: IProductState = {
  productName: "",
  type: ActionTypes.PRODUCTNAME,
};

const triggerProductName = (productName: string) => {
  return {
    productName,
    type: ActionTypes.PRODUCTNAME,
  };
};
const productReducer = (state = initialState, action: ProductActionTypes) => {
  switch (action.type) {
    case ActionTypes.PRODUCTNAME:
      return triggerProductName(action.payload);
    default:
      return state;
  }
};

export default productReducer;
