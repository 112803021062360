import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@mui/material/Alert/Alert";
import { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { ActionTypes, triggerClose } from "../../redux/alert/actions";
import { AppState } from "../../redux/reducers";
import { Grid } from "../ui-kit/grid";
import { Snackbar } from "../ui-kit/snackbar";
import { Typography } from "../ui-kit/typography";
import AlertLinearProgress from "./AlertLinearProgress";
import { Mailto } from "./Mailto";

const duration = 9000;

export default function AlertComponent() {
  const { open, type, message } = useSelector((state: AppState) => state.alert);
  const isErrorEmail = type === ActionTypes.ERROR_EMAIL;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      "& > * + *": {
        minWidth: 0,
      },
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    message: {
      color: theme.palette.primary.contrastText,
    },
    alert: {
      width: 500,
      borderRadius: isErrorEmail ? "" : "8px 8px 0 0",
      minWidth: 0,
      [theme.breakpoints.down("sm")]: {
        width: 350,
      },
      "& .MuiAlert-message": {
        alignSelf: "center",
      },
      "& .MuiAlert-icon": {
        alignSelf: "center",
      },
      "&  .MuiSvgIcon-fontSizeSmall": {
        alignSelf: "center",
      },
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = (
    event?: Event | React.SyntheticEvent,
    reason?: string | SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(triggerClose());
  };

  const severityType = useMemo(() => {
    switch (type) {
      case ActionTypes.ERROR:
        return "error";
      case ActionTypes.ERROR_EMAIL:
        return "error";
      case ActionTypes.WARNING:
        return "warning";
      case ActionTypes.SUCCESS:
        return "success";
      case ActionTypes.INFO:
        return "info";
      default:
        return undefined;
    }
  }, [type]);

  return (
    <div className={classes.root}>
      {message !== "" && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleClose}
          open={open}
        >
          <div>
            <Alert
              className={classes.alert}
              onClose={handleClose}
              severity={severityType}
              variant="filled"
            >
              {isErrorEmail ? (
                <Grid
                  alignItems="flex-start"
                  container
                  direction="column"
                  justifyContent="center"
                  wrap="nowrap"
                >
                  <Grid item xs={12}>
                    <Typography className={classes.message} variant="body1">
                      Something went wrong. Please contact technical support.
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Mailto
                      body={`${message}\n\n--------------------------Please feel free to leave any comments below--------------------------`}
                      email="info@mage.guide"
                      subject={`Error, Date: ${new Date().toLocaleString()}`}
                    >
                      Contact us
                    </Mailto>
                  </Grid>
                </Grid>
              ) : (
                <Typography className={classes.message} variant="body1">
                  {message}
                </Typography>
              )}
            </Alert>
            {!isErrorEmail && (
              <AlertLinearProgress duration={duration} type={type} />
            )}
          </div>
        </Snackbar>
      )}
    </div>
  );
}
