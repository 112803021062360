import { ActionTypes, LoadingActionTypes } from "./actions";

interface ILoadingState {
  loading: boolean;
}

const initialState: ILoadingState = {
  loading: false,
};

const loadingReducer = (state = initialState, action: LoadingActionTypes) => {
  switch (action.type) {
    case ActionTypes.LOADINGON: {
      return { loading: true };
    }
    case ActionTypes.LOADINGOFF: {
      return { loading: false };
    }
    default:
      return state;
  }
};

export default loadingReducer;
