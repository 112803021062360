import {
  AutomatchMatchedItem,
  IAutomatchItem,
} from "../../entities/automatcher";
import { ActionTypes, AutomatchActionTypes } from "./actions";

interface IAutomatchState {
  data: IAutomatchItem[];
  review: IAutomatchItem[];
}

const initialState: IAutomatchState = {
  data: [],
  review: [],
};

// FUNCTIONS TO BE CALLED ON REDUCER.
const triggerUpdateRow = (
  state: IAutomatchState,
  update: {
    row: IAutomatchItem;
    prevMatch: AutomatchMatchedItem;
    newMatch: AutomatchMatchedItem;
  },
) => {
  const dataIndex = state.data.findIndex((item) => item.id === update.row.id);
  const data = [...state.data];
  if (dataIndex >= 0) {
    data[dataIndex] = update.row;
    data[dataIndex].matches[
      data[dataIndex].matches.findIndex(
        (item) => item.id === update.newMatch.id,
      )
    ].chosen_match = true;
    if (update.prevMatch) {
      const prevDataIndex = data[dataIndex].matches.findIndex(
        (item) => item.id === update.prevMatch.id,
      );
      if (prevDataIndex >= 0) {
        data[dataIndex].matches[prevDataIndex].chosen_match = false;
      }
    }
  }

  const reviewIndex = state.review.findIndex(
    (item) => update.row.id === item.id,
  );
  const review = [...state.review];
  if (update.prevMatch && update.newMatch.id === update.prevMatch.id) {
    const remove = review.splice(reviewIndex, 1);
    return {
      ...state,
      data,
      review,
    };
  }
  if (reviewIndex >= 0) {
    review[reviewIndex].matches[
      review[reviewIndex].matches.findIndex(
        (match) => update.newMatch.id === match.id,
      )
    ].chosen_match = true;
    if (update.prevMatch) {
      const prevIndex = review[reviewIndex].matches.findIndex(
        (match) => update.prevMatch.id === match.id,
      );
      if (prevIndex >= 0) {
        review[reviewIndex].matches[
          review[reviewIndex].matches.findIndex(
            (match) => update.prevMatch.id === match.id,
          )
        ].chosen_match = false;
      }
    }
  }
  return {
    ...state,
    data,
    review: reviewIndex === -1 ? [...review, update.row] : review,
  };
};

const triggerAddItem = (
  state: IAutomatchState,
  data: {
    row: IAutomatchItem;
    newItem: AutomatchMatchedItem;
  },
) => {
  const rowIndex = state.data.findIndex((value) => value.id === data.row.id);
  return {
    ...state,
    data: data[rowIndex].matches.splice(-1, 1, data.newItem),
  };
};

const triggerUpdateReview = (state: IAutomatchState, review: any) => {
  return {
    ...state,
    review,
  };
};

const triggerClearReview = (state: IAutomatchState) => {
  return {
    ...state,
    review: [],
  };
};

const triggerUpdateData = (state: IAutomatchState, data: any) => {
  return {
    ...state,
    data,
  };
};

const triggerInitialize = (state: IAutomatchState) => {
  return {
    ...state,
  };
};

const automatchReducer = (
  state = initialState,
  action: AutomatchActionTypes,
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_ROW: {
      return triggerUpdateRow(state, action.payload);
    }
    case ActionTypes.ADD_ITEM: {
      return triggerAddItem(state, action.payload);
    }
    case ActionTypes.UPDATE_REVIEW: {
      return triggerUpdateReview(state, action.payload);
    }
    case ActionTypes.UPDATE_DATA: {
      return triggerUpdateData(state, action.payload);
    }
    case ActionTypes.CLEAR_REVIEW: {
      return triggerClearReview(state);
    }
    case ActionTypes.INITIALIZE: {
      return triggerInitialize(state);
    }
    default:
      return state;
  }
};

export default automatchReducer;
