import React, { ComponentType, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useLocation, useNavigate, useParams } from "react-router-dom";

TagManager.initialize({ gtmId: "GTM-5H6JLW2" });

export function withRouter<T>(Component: ComponentType<T>) {
  const ComponentReactRouterGTM = (props: T) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: "PageView",
          page: location.pathname,
        },
        dataLayerName: "PageDataLayer",
      });
    }, [location]);

    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentReactRouterGTM;
}
