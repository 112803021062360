import { ActionTypes, LayoutActionTypes } from "./actions";

interface ILoadingState {
  activeMenuItem: number;
}

const initialState: ILoadingState = {
  activeMenuItem: -1,
};

const layoutReducer = (state = initialState, action: LayoutActionTypes) => {
  switch (action.type) {
    case ActionTypes.CHANGE_ACTIVE_MENU: {
      return { activeMenuItem: action.payload };
    }

    default:
      return state;
  }
};

export default layoutReducer;
