import { combineReducers } from "redux";

import Alert from "./alert/reducer";
import automatchReducer from "./automatch/reducer";
import Brand from "./brand/reducer";
import Category from "./category/reducer";
import Competitor from "./competitor/reducer";
import Layout from "./layout/reducer";
import Loading from "./loading/reducer";
import Product from "./product/reducer";
import Theme from "./theme/reducer";
import User from "./user/reducer";

const allReducer = combineReducers({
  user: User,
  alert: Alert,
  loading: Loading,
  layout: Layout,
  theme: Theme,
  product: Product,
  competitor: Competitor,
  category: Category,
  automatch: automatchReducer,
  brand: Brand,
});
export default allReducer;

export type AppState = ReturnType<typeof allReducer>;
