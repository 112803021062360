import { IPalette } from "../../theme/main/palette/IPalette";
// eslint-disable-next-line import/no-cycle
import { IUiThemeState } from "./reducer";

export enum ThemeTypes {
  GET_THEME = "GET_THEME",
  TOGGLE_THEME = "TOGGLE_THEME",
  CHANGE_PALETTE = "CHANGE_PALETTE",
  SET_THEME = "SET_THEME",
}

export interface IGetThemeAction {
  type: typeof ThemeTypes.GET_THEME;
}
export interface IToggleThemeAction {
  type: typeof ThemeTypes.TOGGLE_THEME;
}
export interface IChangePalette {
  type: typeof ThemeTypes.CHANGE_PALETTE;
  payload: keyof IPalette;
}
export interface ISetTheme {
  type: typeof ThemeTypes.SET_THEME;
  payload: IUiThemeState;
}

export const triggerGetTheme = () => {
  return {
    type: ThemeTypes.GET_THEME,
  };
};
export const triggerToggleTheme = () => {
  return {
    type: ThemeTypes.TOGGLE_THEME,
  };
};
export const triggerChangePalette = (payload: keyof IPalette) => {
  return {
    type: ThemeTypes.CHANGE_PALETTE,
    payload,
  };
};
export const triggerSetTheme = (payload: IUiThemeState) => {
  return {
    type: ThemeTypes.SET_THEME,
    payload,
  };
};

export type ThemeActionTypes =
  | IGetThemeAction
  | IToggleThemeAction
  | IChangePalette
  | ISetTheme;
