import { cookieStore, localStore } from "../../helpers/storage";
import { ActionTypes, AuthActionTypes } from "./actions";

interface IAlertState {
  isAuthenticated: boolean | undefined;
}

const initialState: IAlertState = { isAuthenticated: false };

const triggerSignInUser = (token: string) => {
  cookieStore.set("token", token);
  return { isAuthenticated: true };
};

const triggerSignOutUser = () => {
  localStore.clear("token");
  cookieStore.clear("token");
  return { isAuthenticated: false };
};

const userReducer = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case ActionTypes.SIGNIN: {
      return triggerSignInUser(action.payload);
    }
    case ActionTypes.SIGNOUT: {
      return triggerSignOutUser();
    }
    default:
      return state;
  }
};

export default userReducer;
