import { ActionTypes, CategoryActionTypes } from "./actions";

interface ICategoryState {
  category: string;
  type: ActionTypes;
}

const initialState: ICategoryState = {
  category: "",
  type: ActionTypes.CATEGORY,
};

const triggerCategoryName = (category: string) => {
  return {
    category,
    type: ActionTypes.CATEGORY,
  };
};
const categoryReducer = (state = initialState, action: CategoryActionTypes) => {
  switch (action.type) {
    case ActionTypes.CATEGORY:
      return triggerCategoryName(action.payload);
    default:
      return state;
  }
};

export default categoryReducer;
